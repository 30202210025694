import * as React from "react";
import { useRef } from "react";

import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import {
  Divider,
  Stack,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grow,
  Hidden,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function SkillSummary() {
  const containerRef = useRef(null);
  const [expanded, setExpanded] = React.useState(true);
  const [expanded2, setExpanded2] = React.useState(false);
  const [expanded3, setExpanded3] = React.useState(false);
  let mostExperience = ["Python", "Java", "JS", "SQL", "C"];
  let devTools = [
    "Git",
    "CI/CD",
    "VSCode",
    "Linux",
    "Postman",
    "Jira",
    "Docker",
    "Eclipse",
  ];

  let hobbies = ["Sports", "Drawing", "Designing", "Reading", "Skate Boarding"];
  let chipColors = ["default", "primary", "info", "success", "error"];
  const handleExpandClick = (nums) => {
    if (nums === 1) {
      setExpanded(!expanded);
      if (expanded) {
        //containerRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
    if (nums === 2) {
      setExpanded2(!expanded2);
      if (expanded2) {
        //containerRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
    if (nums === 3) {
      setExpanded3(!expanded3);
      if (expanded3) {
        //containerRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return (
    <>
      <Paper elevation={1} sx={{ my: 2, borderRadius: 2 }}>
        <Divider>
          <Typography
            variant='h4'
            sx={{ fontFamily: "Roboto Condensed", color: "secondary.light" }}>
            Summary of Skills
          </Typography>
        </Divider>
        <Accordion
          onChange={() => handleExpandClick(1)}
          expanded={expanded}
          ref={containerRef}
          square
          TransitionProps={{ unmountOnExit: true }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'>
            <Typography>Languages</Typography>
          </AccordionSummary>

          <AccordionDetails sx={{ overflowY: "auto", overflowX: "auto" }}>
            <Divider>
              <Chip label='Most Expierence' variant='outlined'></Chip>
            </Divider>
            <br></br>
            <Grid container xs={12} spacing={1} justifyContent={"center"}>
              {mostExperience.map((language) => {
                return (
                  <>
                    <Grid item>
                      <Chip
                        label={language}
                        color={
                          chipColors[
                            Math.floor(Math.random() * chipColors.length)
                          ]
                        }
                      />
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion
          onChange={() => handleExpandClick(2)}
          ref={containerRef}
          TransitionProps={{ unmountOnExit: true }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel2a-content'
            id='panel2a-header'>
            <Typography>Developer Tools</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ overflowY: "auto", overflowX: "auto" }}>
            <Grid container xs={12} spacing={1} justifyContent={"center"}>
              {devTools.map((language) => {
                return (
                  <>
                    <Grid item>
                      <Chip
                        label={language}
                        color={
                          chipColors[
                            Math.floor(Math.random() * chipColors.length)
                          ]
                        }
                      />
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion
          TransitionProps={{ unmountOnExit: true }}
          onChange={() => handleExpandClick(3)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel2a-content'
            id='panel2a-header'>
            <Typography>Other Skills/Hobies</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ overflowY: "auto", overflowX: "auto" }}>
            <Grid container xs={12} spacing={1} justifyContent={"center"}>
              {hobbies.map((language) => {
                return (
                  <>
                    <Grid item>
                      <Chip
                        label={language}
                        color={
                          chipColors[
                            Math.floor(Math.random() * chipColors.length)
                          ]
                        }
                      />
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Paper>
    </>
  );
}

export default SkillSummary;
